
.homepage-wrapper .categories-section-wrapper {
    padding: 30px;
    margin:auto;
    /* max-width: 700px; */
}

.homepage-wrapper .categories-section-wrapper h3, .testimonials-section h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff;
}

.voted-contestants-section-wrapper h3 {
    color: #fff;
}

.homepage-wrapper .categories-section-wrapper .categories-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    max-width: 500px;
    margin: auto;
}

.homepage-wrapper .categories-section-wrapper .categories-wrapper .category {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 1rem;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
}

.marquee-wrapper {
    margin:20px 2rem;
    display: flex;
    align-items: center;
    height: 100px;
}

.marquee-wrapper img {
    height: 100%;
}

.live-visitors{
    padding: 12px 15px;
    margin: 15px 15px 15px 0;
    border-radius: 8px;
    background-color: #000000;
    font-weight: 600;
    font-size: 12px;
    color: #e4e4e4;
}

.live-visitors span {
    vertical-align: middle;
    padding-top: 5px;
}

.live-wrapper{
    display: flex;
    gap: 10px;
    align-items: center;
}

.live {
    width:56px;
    height: 100%;
    border-radius: 8px;
}

.live-visitors img {
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
}


.swiper-pagination-bullet {
    background: #fff !important;
    opacity:0.5 !important;
}

.categories-wrapper .double-categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 30px) !important;
}

.swiper-pagination-bullet-active {
    opacity: 1 !important;
}

.homepage-wrapper .contestantsPage-wrapper {
    margin: 30px auto;
}

@media (max-width:900px) {
    .homepage-wrapper {
        margin:auto auto 58px auto
    }
}

@media (max-width:500px) {
    .homepage-wrapper .categories-section-wrapper .categories-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}


.testimonials-wrapper {
    display: grid;
    padding: 0 15px 0px 15px;
    margin-bottom: 60px;
    gap: 30px;
    grid-template-columns: repeat(1,1fr);
    place-items: center;
}

.testimonial {
    border-radius: 5px;
}

.testimonial video {
    width:100%;
}
.testimonial .play-btn {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100px;
    z-index: 5;
    cursor: pointer;
}

.header_logo {
    filter: invert(1);
}