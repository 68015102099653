.contestantsPage-wrapper {
    max-width: 1000px;
    padding: 15px;
    margin: 50px auto 100px auto;
    text-align: center;
}

.contestantsPage-wrapper .contestants-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.contestantsPage-wrapper .contestant-profileImage {
    border-radius: 5px;
    object-fit: cover;
    aspect-ratio: 1;
}

.contestantsPage-wrapper .category-btn {
    margin: auto auto 2rem auto;
}

.contestantsPage-wrapper p {
    text-align: center;
    color: rgb(255, 255, 255);
}


.contestantsPage-wrapper .contestant-card {
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
}

.contestantsPage-wrapper .contestant-card a p {
    margin-block: 30px;
    font-size: 12px;
}

.contestantsPage-wrapper .contestant-card button img {
    width: 14px;
    vertical-align: middle;
}

.contestantsPage-wrapper .contestant-card button span {
    font-size: 12px;
    margin-left: 3px;
    vertical-align: middle;
}

.contestantsPage-wrapper .contestant-card button {
    margin: auto;
    position: absolute;
    display: inline-block;
    max-width: fit-content;
    inset: 0 0 auto 0;
    max-height: fit-content;
}

.relative {
    position: relative;
}

@media (max-width:490px) {
    .profile-buttons-wrapper .profile-button p {
        font-size: 12px;
    }
}

@media (max-width:400px) {
    .contestantsPage-wrapper .contestant-card button {
     display: grid;
     place-items: center;
    }
    .contestantsPage-wrapper .contestant-card a p {
        margin: 40px auto 30px auto;
    }
}