.login-form-wrapper{
    max-width: 500px;
    margin: 100px auto;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2rem;
    border-radius: 5px;
}

.login-form-wrapper h3 {
    margin-bottom: 30px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: #fff;
    border-bottom: 1px solid;
}

.login-form-wrapper label {
    color: #fff;
}

.login-form-wrapper p {
    color: #fff;
}

.login-form-wrapper button {
    margin: 10px 0 20px;
}

.login-form-wrapper input {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    margin: 3px 0 10px 0;
    border: none;
}

@media (max-width:580px) {
    .login-form-wrapper{
        margin: 50px 20px 150px;
    }
}