@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    min-height: 100vh;
    /* background: linear-gradient(180deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 116, 1) 25%, rgba(252, 143, 69, 1) 45%, rgba(252, 143, 69, 1) 55%, rgba(253, 29, 116, 1) 75%, rgba(131, 58, 180, 1) 100%); */
    background-size: cover;
    background-image: radial-gradient(#753da2, #380562);
    position: relative;
}

/* ---------------------------- */


button {
    font-size: 12px;
    cursor: pointer;
    padding: 10px 30px;
    text-align: center;
    color: #5c00fd;
    border-radius: 100px;
    display: block;
    font-weight: 800;
    background-color: #fff;
    border: none;
}

.btn-inverse {
    color: #fff;
    background-color: #5c00fd;
}


.categories-section-wrapper button {
    width: 100%;

}

main{
    min-height: 100vh;
}

a {
    text-decoration: none;
    color: #ffffff;
}

.search-wrapper li a {
    color: #000;
}

li {
    list-style: none;
}

img {
    width: 100%;
}

@media (max-width:680px){
    button{
        padding: 7px 11px;
    }
} 

/* ---------------------------- */

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .header_logo {
    height: 80px;
    padding: 15px;
    vertical-align: middle;
}


nav {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    align-items: center;
}


.search-container .search-wrapper {
    position: absolute;
    inset: 80px 15px auto;
    z-index: 100000;
}

.search-wrapper ul {
    max-width: 700px;
    margin:auto;
    background-color: #fff;
    overflow-y: scroll;
    border: none;
    max-height: 300px;
}

.search-wrapper ul li{
    padding: 5px 15px;
    font-size: 12px;
}

.search-wrapper-desktop ul li:hover {
    background-color: #0000001f;
}

.search-wrapper .search-form {
    display: flex;
}


.search-wrapper .search-form {
    max-width: 700px;
    margin: auto;
    border-bottom: solid 1px grey;
}

.search-wrapper-mobile .search-input {
    border-radius: 0;
    border: solid 1px #fff ;
}

.search-wrapper .search-input{
    padding: 10px 15px;
    border: none;
    font-size: 12px;
    width: 100%;
}

.search-wrapper .search-form input:focus{
    outline: none;
}

.search-wrapper .search-form button {
    padding: 10px 20px 10px 15px;
    border-radius: 0;
}

.search-wrapper svg {
    width: 14px;
    vertical-align: middle;
}

.search-icon , .close-icon {
    fill: #fff;
    vertical-align: middle;
    margin-inline: 15px;
    cursor: pointer;
}

.search-wrapper-mobile .search-form button {
    border-radius: 0;
    cursor: pointer;
}

.search-wrapper-mobile {
    display: none;
}


@media (max-width:1160px) {
    nav {
        position: fixed;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        background-color: #fff;  
        gap: 0;
    }
 .search-wrapper-mobile {
    display: block;
 }
    .search-wrapper-desktop {
        display: none;
    }
}