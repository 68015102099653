.contestantProfile-wrapper{
    max-width: 600px;
    margin: 100px auto;
    /* background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18); */
    border-radius: 10px;
}

.contestant-profileImage-details-wrapper {
padding: 10px 10px 40px 10px;
background-color: #fff;
border-radius: 10px 10px 0 0;
}

.contestant-profileImage-details-wrapper .profile-image-vote-btn-wrapper .profile-image-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contestant-profileImage-details-wrapper .profile-image-vote-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.profile-image-details {
    display: grid;
    gap: 10px;
}
.contestantProfile-wrapper h2 {
    text-align: center;
}

.contestant-details-wrapper p {
    margin-bottom: 1rem;
}

.contestant-details-wrapper p button  {
    display: flex;
    align-items: center;
    gap: 5px;
}

.contestant-details-wrapper p a svg {
    height: 20px;
    width: 20px;
}

.contestant-details-wrapper {
    padding: 10px;
    margin-top: 20px;
    background-color: #fff;
}

.contestantProfile-wrapper .posts-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
}

.contestantProfile-wrapper .posts-wrapper .post {
    border-radius: 5px;
}
.contestantProfile-wrapper .profile-image {
    max-width: 80px;
    border-radius: 50px;
    border: solid 2px #5f5f5f;
}

.contestantProfile-wrapper .slider {
    height: 600px;
    width: 100%;
    margin: auto;
}

.contestantProfile-wrapper .slider .previousButton svg polygon, .contestantProfile-wrapper .slider .nextButton svg polygon {
    fill: #ffffff9f;
}


.vote-img-wrapper {
    background: #fff;
    color: #5c00fd;
    padding: 10px 20px;
    font-size: 14px;
    margin: 20px auto auto auto;
    box-shadow: 10px 10px 30px #d9d9d9b8, -10px -10px 30px #d1d0df9e;margin-bottom: 20px;
}

.edit-votes-button {
    background: #ffffff;
    box-shadow: 10px 10px 30px #d9d9d9b8, -10px -10px 30px #d1d0df9e;margin-bottom: 20px;
    margin-top: 20px;
}

.vote-img-wrapper:active {
    box-shadow: none;
    filter: grayscale(0);
    opacity: 1;
}

.vote-img-wrapper img {
    width: 18px;
    height: 18px;
    margin: auto;
    vertical-align: middle; 
    margin-right: 5px;
    opacity: 0.5;
    filter: grayscale(1);
}

.vote-img-wrapper .red-heart {
    opacity: 1;
    filter: grayscale(0);
}

.profile-buttons-wrapper{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.profile-buttons-wrapper .profile-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 9px;
    text-align: center;
}

.profile-buttons-wrapper .profile-button p {
    font-size: 12px;
}

.profile-buttons-wrapper .profile-button a {
    color:#000
}

.profile-buttons-wrapper .profile-button svg {
    height: 20px;
}

.contestant-profileImage-details-wrapper .swiper-wrapper {
    align-items: center;
}

#share-profile {
    cursor: pointer;
}

.suggestedContestants-wrapper {
    margin: 50px auto 100px auto;
}

.suggestedContestants-wrapper .contestants-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.suggestedContestants-wrapper .contestant-profileImage {
    border-radius: 5px;
    object-fit: cover;
    aspect-ratio: 1;
}

.suggestedContestants-wrapper .category-btn {
    margin: auto auto 2rem auto;
}

.suggestedContestants-wrapper p {
    text-align: center;
    color: rgb(255, 255, 255);
}

.suggestedContestants-wrapper .contestant-card {
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
}

.suggestedContestants-wrapper .contestant-card a p {
    margin-block: 30px;
    font-size: 12px;
}

.suggestedContestants-wrapper .contestant-card button img {
    width: 14px;
    vertical-align: middle;
}

.suggestedContestants-wrapper .contestant-card button span {
    font-size: 12px;
    margin-left: 3px;
    vertical-align: middle;
}

.fill-black {
    fill:#000 !important;
}

.suggestedContestants-wrapper .contestant-card button {
    margin: auto;
    position: absolute;
    display: inline-block;
    max-width: fit-content;
    inset: 0 0 auto 0;
    max-height: fit-content;
}

.relative {
    position: relative;
}

.lightbox-overlay {
    background-color: aliceblue;
    position: fixed;
    inset: 0;
    z-index: 1000;
}

  
.lightbox-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    
}

.lightbox-container {
    position: fixed;
    inset:0; 
    margin:auto;
    padding: 50px 15px 130px 15px;
}

.profile-button a svg {
    margin-bottom: 4px;
}

.profile-button svg{
    cursor: pointer;
}

@media (max-width:490px) {
    .profile-buttons-wrapper .profile-button p {
        font-size: 12px;
    }
}

@media (max-width:600px) {
    .contestantProfile-wrapper {
        margin: 50px 15px 100px;
    }
}

@media (max-width:400px) {
    .suggestedContestants-wrapper .contestant-card button {
     display: grid;
     place-items: center;
    }
    .suggestedContestants-wrapper .contestant-card a p {
        margin: 40px auto 30px auto;
    }
}
