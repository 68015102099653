.post-details-wrapper {
    max-width: 1200px;
    display: grid;
    position: relative;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    margin: 100px auto;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
}

.post-details-wrapper input {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: none;
}

.post-details-wrapper img {
    border-radius: 5px;
    position: sticky;
    top: 2rem;
}

.post-details-wrapper .comments-wrapper {
    background-color: #fff;
    padding: 2rem;
    margin: 1rem 0;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
}

@media (max-width:700px) {
    .post-details-wrapper {
        grid-template-columns: 1fr;
    }
    .post-details-wrapper img {
        position: relative;
        top: auto;
    }
}