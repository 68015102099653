.signup-form-wrapper{
    max-width: 700px;
    margin: 100px auto;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2rem;
    border-radius: 5px;
}

.signup-form-wrapper h3 {
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    color: #fff;
    text-transform: uppercase;
}

.signup-form-wrapper button {
    margin: 10px 0 20px;
}

.signup-form-wrapper label {
    color: #fff;
}

.signup-form-wrapper input, .signup-form-wrapper select, .signup-form-wrapper select option {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    margin: 3px 0 10px 0;
    border: none;
}

#otpInput::-webkit-outer-spin-button,
#otpInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#otpInput[type=number]{
    -moz-appearance: textfield;
}

@media (max-width:720px) {
    .signup-form-wrapper{
        margin: 50px 20px 150px;
    }
}