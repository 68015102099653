.registration-form-wrapper{
    max-width: 900px;
    margin: 100px auto;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2rem;
    border-radius: 5px;
}

.registration-form-wrapper h3 {
    margin-bottom: 30px;
    padding-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid;
    color: #fff;
}

.registration-form-wrapper button {
    margin: 10px 0 20px;

}

.registration-form-wrapper input, .registration-form-wrapper select, .registration-form-wrapper select option {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin: 3px 0 10px 0;
    font-size: 14px;
}

.registration-form-wrapper .postImages-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.registration-form-wrapper .postImages-wrapper .postImages, .registration-form-wrapper .profileImage {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
}

.registration-form-wrapper .profileImage {
    max-width: 300px;
}

.instagramProfileLink {
    display: flex;
    align-items: center;
    gap: 10px;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 1rem;
    border-radius: 5px;
    margin-top: 1rem;
    font-size: 14px;
    color: #000000;
}

.registration-form-wrapper label, .registration-form-wrapper p {
    color: #fff;
}

@media (max-width:480px) {
    .registration-form-wrapper .postImages-wrapper {
        grid-template-columns: 1fr ;
    }
    .instagramProfileLink {
        flex-direction: column;
    }
}

@media (max-width:920px) {
    .registration-form-wrapper{
        margin: 50px 20px 150px;
    }
}