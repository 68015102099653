.dashboard-wrapper {
    max-width: 1000px;
    /* padding: 2rem; */
    margin: 100px auto;
    /* background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18); */
    border-radius: 5px;
}

.dashboard-wrapper .voter-dashboard-heading {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
}

.dashboard-wrapper .contestants-section-wrapper, .dashboard-wrapper .profile-details-wrapper, .dashboard-wrapper .comments-section-wrapper, .contest-management-wrapper, .dashboard-wrapper .visitor-count-section, .dashboard-wrapper .hero-slider-images-wrapper, .hero-slider-videos-wrapper {
    padding: 15px;
    background-color: aliceblue;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
    margin: 2rem 0;
}

.dashboard-wrapper h3{
    text-transform: uppercase;
    border-bottom: 1px solid;
    padding-bottom: 10px;
}

.dashboard-wrapper .delete-button {
    margin-top: 10px;
    width: 100%;
}

.dashboard-wrapper input {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    margin: 3px 0 10px 0;
    border: none;
}


.posts-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.comment {
    margin: 2rem 0;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
    padding: 1rem;
}

 .post img, .dashboard-wrapper .contestant-profileImage {
    border-radius: 5px;
    object-fit: cover;
    aspect-ratio: 1;
    cursor: pointer;
}



.dashboard-wrapper .contestant-details-and-form-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.dashboard-wrapper .post p {
    text-align: center;
    color: black;
}

.dashboard-wrapper .voter-details {
    background-color: #fff;
}

.dashboard-wrapper input {
    border: 2px #000 solid;
}

.dashboard-wrapper select {
    padding: 10px;
    border: 2px #000 solid;
    margin-bottom: 10px;
    border-radius: 5px;
}

.dashboard-wrapper .contestant-form {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 5px;
    padding: 1rem;

}
.dashboard-wrapper .contestant-details{
    padding: 1rem;
}

.voted-contestants-section-wrapper {
    padding: 15px;
}

.votedContestants-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.votedContestants-wrapper .votedContestant-card {
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
}

.votedContestants-wrapper .votedContestant-card a p {
    margin: 40px auto 20px auto;
    font-size: 12px;
}

.votedContestants-wrapper .votedContestant-card button img {
    width: 14px;
    vertical-align: middle;
}

.votedContestants-wrapper .votedContestant-card button span {
    font-size: 12px;
    margin-left: 3px;
    vertical-align: middle;
}

.votedContestants-wrapper .votedContestant-card button {
    margin: auto;
    position: absolute;
    display: inline-block;
    max-width: fit-content;
    inset: 0 0 auto 0;
    max-height: fit-content;
}

.winners-wrapper h3 {
    color: #fff;
}

.relative {
    position: relative;
}

.votedContestants-wrapper p {
    text-align: center;
    color: #fff;
}

.contest-management-wrapper  ul, .winners-wrapper ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

.contest-management-wrapper  ul li, .winners-wrapper ul li  {
    background-color: #fff;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: solid 2px #000;
}

.contest-management-wrapper  ul li button, .winners-wrapper ul li button {
    background-color: #5c00fd;
    color: #fff;
    margin-top: 10px;
}

.winners-wrapper ul li a {
    color: #5c00fd;
}
 
@media (max-width:680px) {
    .dashboard-wrapper .contestants-section-wrapper .posts-wrapper{
        grid-template-columns: 1fr !important;
    }
}

@media (max-width:1000px) {
    .dashboard-wrapper {
        margin: 50px 15px 150px;
    }
    .contest-management-wrapper  ul, .winners-wrapper ul {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:600px) {
    .contest-management-wrapper ul, .winners-wrapper ul {
        grid-template-columns: 1fr;
    }
}

@media (max-width:400px) {
    .votedContestants-wrapper .votedContestant-card button {
     display: grid;
     place-items: center;
    }
    .votedContestants-wrapper .votedContestant-card a p {
        margin: 40px auto 30px auto;
    }
}